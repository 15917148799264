body {
  margin: 0;
  font-family: 'Lexend', 'DM Sans' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app {
  height: 100vh;
}

/* nprogess styles */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: rgb(217, 193, 255);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px rgb(217, 193, 255), 0 0 5px rgb(217, 193, 255);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* react-elastic-carousel */
.rec.rec-slider-container {
  margin: 0px !important;
}
.rec .rec-item-wrapper:focus-visible {
  outline: none !important;
}
.rec .rec-item-wrapper div:focus-visible {
  outline: none !important;
}

.br-4 .rec.rec-slider-container {
  border-radius: 4px !important;
}

.br-6 .rec.rec-slider-container {
  border-radius: 6px !important;
}

.br-8 .rec.rec-slider-container {
  border-radius: 8px !important;
}

.br-10 .rec.rec-slider-container {
  border-radius: 10px !important;
}

.br-12 .rec.rec-slider-container {
  border-radius: 12px !important;
}

.br-14 .rec.rec-slider-container {
  border-radius: 14px !important;
}

.br-16 .rec.rec-slider-container {
  border-radius: 16px !important;
}

.br-18 .rec.rec-slider-container {
  border-radius: 18px !important;
}

.br-20 .rec.rec-slider-container {
  border-radius: 20px !important;
}

.br-22 .rec.rec-slider-container {
  border-radius: 22px !important;
}

.br-24 .rec.rec-slider-container {
  border-radius: 24px !important;
}

/* Styles for React Responsive Table */
.responsiveTable {
  width: 100%;
  border-spacing: 0px;
}

.responsiveTable td .tdBefore {
  display: none;
}

.table-box {
  border: 1px solid #e3e5e5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  padding: 0px;
  position: relative;
}
.table-wrapper {
  overflow-x: scroll;
}
.show-border .header-item:nth-child(1) {
  box-shadow: 0px 10px 10px #bbb;
}
.header-item {
  text-align: left;
  padding: 0.75em 1.5em;
  background: #f9fafb;
  border-radius: 0px;
  height: 44px;
  border-bottom-width: 1px;
  border-bottom-color: #e3e5e5;
  /* border-top-left-radius: 8px; */
  /* border-top-right-radius: 8px; */
  font-size: 0.75em;
  font-weight: 500;
  font-family: 'SatoshiMedium', 'DM Sans', sans-serif;
  white-space: nowrap;
}
.header-item:nth-child(1) {
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  background: #f2f4f5;
}

.row:nth-child(odd) {
  background: #ffffff;
}

.row:nth-child(even) {
  background: #f9fafb;
}
.show-border .column:nth-child(1) {
  box-shadow: 0px 10px 10px #bbb;
  border-bottom-left-radius: 0px;
  z-index: 1000;
}

.column {
  text-align: left;
  padding: 1em 1.5em;
  font-size: 0.875em;
  height: 72px;
  word-break: break-word;
  font-weight: 400;
  font-family: 'SatoshiRegular', 'DM Sans', sans-serif;
  white-space: nowrap;
  align-items: center;
}

.column:nth-child(1) {
  position: sticky;
  left: 0;
  display: flex;
}

.column:nth-child(odd).column:nth-child(1) {
  background: inherit;
}

.column:nth-child(1).last-child {
  border-bottom-left-radius: 8px;
}

.column:nth-last-child(1).last-child {
  border-bottom-right-radius: 8px;
}

.plus {
  color: #039855;
  font-weight: 700;
  font-family: 'SatoshiBold', 'DM Sans', sans-serif;
}

.minus {
  color: #23262f;
  font-weight: 700;
  font-family: 'SatoshiBold', 'DM Sans', sans-serif;
}

.last-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .show-border .header-item:nth-child(1) {
    box-shadow: 0px 10px 10px #bbb;
    position: absolute;
    left: 0;
    display: flex;
    max-width: 120px;
    min-width: 120px;
  }
  .show-border .column:nth-child(1) {
    box-shadow: 0px 10px 10px #bbb;
    border-bottom-left-radius: 0px;
    z-index: 1000;
    position: absolute;
    left: 0;
    display: flex;
    max-width: 120px;
    min-width: 120px;
    overflow: auto;
  }
}
